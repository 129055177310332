<template>
  <div class="tree-map-outer-wrapper">
    <div id="tree-map-container" class="mt-2 regional-tree"></div>
    <div class="legends-container">
      <div class="legend-inside">
        <div
          class="legend"
          v-for="(region, index) in root.children"
          :key="'region-legend' + index">
        <!-- {{regionColors(index) }} -->
          <span
            class="colored-rect"
            v-bind:style="{ background: regionColors(index) }"></span>
          <p>{{ region.data.name }}</p>
        </div>
      </div>
    </div>
    <div class="local-loader-container" v-if="mapLoader">
      <div class="inava-loader"></div>
    </div>
  </div>
</template>

<script>
// import { mapState, mapActions } from 'vuex'
import * as d3 from 'd3'
import { mapActions, mapState } from 'vuex'
import tooltip from '@/util/tooltip'
import { regionColors } from '@/util/util.js'

export default {
  name: 'RegionalTreeMap',
  props: ['data', 'selected'],
  data () {
    return {
      newData: null,
      observer: null,
      isEmpty: null
    }
  },
  computed: {
    ...mapState('tam', ['mapLoader', 'regionView']),
    root () {
      let root = null
      root = d3
        .hierarchy(this.data)
        .eachBefore(function (d) {
          d.data.id = (d.parent ? d.parent.data.id + '.' : '') + d.data.name
        })
        .sort((a, b) => b.data.marketValue - a.data.marketValue)
        .sum(function (d) {
          return d.children ? 0 : d.marketValue
        })
      return root
    }
  },
  watch: {
    data () {
      // if (this.selected === 0) {
      // this.renderZoomableChart()
      // } else {
      this.resizeWindow()
      // }
    }
  },
  mounted () {
    // if (this.selected === 0) {
    // this.renderZoomableChart()
    // } else {
    this.resizeWindow()
    // }
  },
  methods: {
    ...mapActions('tam', ['updateMapLoader']),
    regionColors,
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        // console.log('Resizing')
        // if (this.data.length) {
        if (d3.select('.tree-map-outer-wrapper').node() != null) {
          this.renderChart()
          // }
        }
      })
      this.observer.observe(document.querySelector('.tree-map-outer-wrapper'))
    },
    /**
     * This method is used to render the Regional Treemap
     * @public
     */
    renderChart () {
      d3.selectAll('#tree-map-container > svg').remove()
      // set the dimensions and margins of the graph
      const margin = { top: 0, right: 5, bottom: 0, left: 5 }
      const container = document.querySelector('#tree-map-container')
      const width = container.clientWidth - margin.left - margin.right
      const height = container.clientHeight - margin.top - margin.bottom
      const root = this.root
      const regionView = this.regionView
      const selected = this.selected

      // append the svg object to the body of the page
      const svg = d3
        .select('#tree-map-container')
        .append('svg')
        .attr('class', 'treemap')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)

      function createTreeMap (data) {
        // Then d3.treemap computes the position of each element of the hierarchy
        // The coordinates are added to the root object above
        d3
          .treemap()
          .tile(d3.treemapSquarify)
          .size([width, height])
          .round(true)
          .padding(3)(root)

        // use this information to add rectangles:

        const leavesGroup = svg
          .selectAll('rect')
          .data(root.leaves())
          .join('g')
          .attr('class', 'leaf')

        leavesGroup
          .append('rect')
          .attr('x', function (d) {
            return d.x0
          })
          .attr('y', function (d) {
            return d.y0
          })
          .attr('width', function (d) {
            return d.x1 - d.x0
          })
          .attr('height', function (d) {
            return d.y1 - d.y0
          })
          .style('fill', function (d) {
            let color = null
            if (regionView !== 1 && selected === 0) {
              color = regionColors(
                root.children
                  .map((region) => region.data.name)
                  .indexOf(d.parent.data.name)
              )
            } else {
              color = regionColors(
                root
                  .leaves()
                  .map((leaf) => leaf.data.name)
                  .indexOf(d.data.name)
              )
            }
            return color
          })
          .attr('fill', 'var(--voc-bar-background-color)')
          .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
          .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
          .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))

        const leaves = document.querySelectorAll('.leaf > rect')

        // This function is responsible for generating the tooltip when the cursor is hovered over the treemap tiles/leaves.
        function tooltipFun (event, d, type) {
          let data = {}
          switch (type) {
            case 'in':
              // eslint-disable-next-line no-case-declarations
              data = {
                '': d.data.name,
                'Market Value': `$${d.data.marketValue.toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}M`,
                'CAGR Growth': `${d.data.cagrGrowth.toFixed(1)}%`
              }
              break
          }
          tooltip(event, data, type)
        }

        // and to add the text labels
        const leafLeftPadding = 10
        const leafBottomPadding = 20
        const leafRightPadding = 25
        const intersectionRange = 20
        const marketValueBottomPadding = 12

        leavesGroup
          .append('text')
          .attr('class', 'leaf-name')
          .text(function (d) {
            return d.data.name
          })
          .attr('x', function (d) {
            return d.x0 + leafLeftPadding
          }) // +10 to adjust position (more right)
          .attr('y', function (d, i) {
            const bbox = d3.select(this).node().getBoundingClientRect()
            const text = d3.select(this).node()
            const textBCRect = text.getBoundingClientRect()
            const leaf = leaves[i]
            const leafBCRect = leaf.getBoundingClientRect()
            if (textBCRect.right > leafBCRect.right) {
              return d.y0 + bbox.width + leafBottomPadding
            } else {
              return d.y0 + leafBottomPadding
            }
            // return d.y0 + 20
          }) // +20 to adjust position (lower)
          .attr('transform', function (d, i) {
            // const rectWidth = d.x1 - d.x0
            const text = d3.select(this).node()
            const textBCRect = text.getBoundingClientRect()
            const leaf = leaves[i]
            const leafBCRect = leaf.getBoundingClientRect()
            if (textBCRect.right > leafBCRect.right) {
              return 'rotate(-90)'
            } else {
              return ''
            }
          })
          .style('transform-box', 'fill-box')
          .attr('font-size', '14px')
          .attr('font-family', 'Quicksand')
          .attr('font-weight', 500)
          .attr('fill', 'black')
          .style('display', function (d, i) {
            const leaf = leaves[i]
            const leafBCRect = leaf.getBoundingClientRect()
            const text = d3.select(this)
            const textBCRect = text.node().getBoundingClientRect()
            if (tileOverflow(textBCRect, leafBCRect)) {
              return 'none'
            }
            return 'block'
          })
          .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
          .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
          .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))

        const leafNames = document.querySelectorAll('.leaf-name')

        leavesGroup
          .append('text')
          .attr('class', 'leaf-market-value')
          .text(function (d) {
            return (
              '$' +
              d.data.marketValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) +
              'M'
            )
          })
          .attr('x', function (d) {
            return d.x0 + leafLeftPadding
          }) // +10 to adjust position (more right)
          .attr('y', function (d, i) {
            const text = d3.select(this).node()
            const textBCRect = text.getBoundingClientRect()
            const leaf = leaves[i]
            const leafBCRect = leaf.getBoundingClientRect()
            if (textBCRect.right > leafBCRect.right) {
              return d.y1
            } else {
              return d.y1 - marketValueBottomPadding
            }
          }) // +20 to adjust position (lower)
          .attr('transform', function (d, i) {
            const text = d3.select(this).node()
            const textBCRect = text.getBoundingClientRect()
            const leaf = leaves[i]
            const leafBCRect = leaf.getBoundingClientRect()
            if (textBCRect.right > leafBCRect.right) {
              return 'rotate(-90)'
            } else {
              return ''
            }
          })
          .style('transform-box', 'fill-box')
          .attr('font-size', '18px')
          .attr('font-weight', 600)
          .attr('fill', 'black')
          .style('display', function (d, i) {
            const leaf = leaves[i]
            const leafBCRect = leaf.getBoundingClientRect()
            const leafNameText = leafNames[i]
            const leafNameTextBCR = leafNames[i].getBoundingClientRect()
            const text = d3.select(this).node()
            const textBCRect = text.getBoundingClientRect()
            if (
              tileOverflow(textBCRect, leafBCRect) ||
              (textBCRect.x - leafNameTextBCR.x > -intersectionRange &&
                textBCRect.x - leafNameTextBCR.x < intersectionRange &&
                leafNameText.getAttribute('transform') === 'rotate(-90)' &&
                leafNameText.getAttribute('display') !== 'none') ||
              (textBCRect.x - leafNameTextBCR.x > -intersectionRange &&
                textBCRect.x - leafNameTextBCR.x < intersectionRange &&
                textBCRect.y - leafNameTextBCR.y > -intersectionRange &&
                textBCRect.y - leafNameTextBCR.y < intersectionRange)
            ) {
              return 'none'
            }
            return 'block'
          })
          .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
          .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
          .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))

        leavesGroup
          .append('text')
          .attr('class', 'leaf-cagr-growth')
          .text(function (d) {
            return `CAGR Growth ${d.data.cagrGrowth.toFixed(1)}%`
          })
          .attr('x', function (d, i) {
            const rectWidth = d.x1 - d.x0
            const marketValueText =
              document.querySelectorAll('.leaf-market-value')[i]
            const cagrValueText = d3.select(this).node()
            const text = d3.select(this).node()
            const textBCRect = text.getBoundingClientRect()
            const leaf = leaves[i]
            const leafBCRect = leaf.getBoundingClientRect()
            if (
              textBCRect.right > leafBCRect.right ||
              marketValueText.getComputedTextLength() +
              cagrValueText.getComputedTextLength() >
              rectWidth - 15
            ) {
              return d.x1 - leafRightPadding
            }
            return d.x1 - leafRightPadding
          }) // +10 to adjust position (more right)
          .attr('text-anchor', function (d, i) {
            const rectWidth = d.x1 - d.x0
            const marketValueText =
              document.querySelectorAll('.leaf-market-value')[i]
            const cagrValueText = d3.select(this).node()
            const text = d3.select(this).node()
            const textBCRect = text.getBoundingClientRect()
            const leaf = leaves[i]
            const leafBCRect = leaf.getBoundingClientRect()
            if (
              textBCRect.right > leafBCRect.right ||
              marketValueText.getComputedTextLength() +
              cagrValueText.getComputedTextLength() >
              rectWidth - 15
            ) {
              return 'start'
            }
            return 'end'
          })
          .attr('y', function (d, i) {
            const text = d3.select(this).node()
            const textBCRect = text.getBoundingClientRect()
            const leaf = leaves[i]
            const leafBCRect = leaf.getBoundingClientRect()
            if (textBCRect.right > leafBCRect.right) {
              return d.y1
            } else {
              if (d3.select(this).attr('text-anchor') === 'end') {
                return d.y1 - leafBottomPadding
              } else {
                return d.y1 - leafBottomPadding / 4
              }
            }
            // return d.y0 + 20
          }) // +20 to adjust position (lower)
          .attr('transform', function (d, i) {
            const rectWidth = d.x1 - d.x0
            const marketValueText =
              document.querySelectorAll('.leaf-market-value')[i]
            const cagrValueText = d3.select(this).node()
            const text = d3.select(this).node()
            const textBCRect = text.getBoundingClientRect()
            const leaf = leaves[i]
            const leafBCRect = leaf.getBoundingClientRect()
            if (
              textBCRect.right > leafBCRect.right ||
              marketValueText.getComputedTextLength() +
              cagrValueText.getComputedTextLength() >
              rectWidth - 15
            ) {
              return 'rotate(-90)'
            } else {
              return ''
            }
          })
          .style('transform-box', 'fill-box')
          .attr('font-size', '15px')
          .attr('fill', 'black')
          .style('display', function (d, i) {
            const leaf = leaves[i]
            const marketValueText =
              document.querySelectorAll('.leaf-market-value')[i]
            const leafBCRect = leaf.getBoundingClientRect()
            const text = d3.select(this).node()
            const textBCRect = text.getBoundingClientRect()
            const marketValueTextBCRect =
              marketValueText.getBoundingClientRect()
            if (
              tileOverflow(textBCRect, leafBCRect) ||
              tileOverflow(textBCRect, marketValueTextBCRect)
            ) {
              return 'none'
            }
            return 'block'
          })
          .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
          .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
          .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))

        // Function to check if text overflown out of tile
        function tileOverflow (text, leaf) {
          if (
            text.right > leaf.right ||
            text.bottom > leaf.bottom ||
            text.left < leaf.left ||
            text.top < leaf.top
          ) {
            return true
          }
          return false
        }
      }
      createTreeMap(this.data)
      this.updateMapLoader(false)
    },
    renderZoomableChart () {
      d3.selectAll('#tree-map-container > svg').remove()
      // set the dimensions and margins of the graph
      const container = document.querySelector('#tree-map-container')
      const margin = { top: 50, right: 5, bottom: 0, left: 5 }
      const width = container.clientWidth - margin.left - margin.right
      //   const height = containerWidth - margin.top - margin.bottom
      //   const width = 900 - margin.left - margin.right
      const height = container.clientHeight - margin.top - margin.bottom
      const x = d3.scaleLinear().rangeRound([0, width])
      const y = d3.scaleLinear().rangeRound([0, height])
      const name = (d) =>
        d
          .ancestors()
          .reverse()
          .map((d) => d.data.name)
          .join(' > ')
      const format = d3.format(',d')
      const root = this.root
      const data = this.data

      const color = d3
        .scaleOrdinal()
        .domain(root.children.map((d) => d.data.name))
        .range(root.children.map((d, i) => regionColors(i)))

      const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          const r = (Math.random() * 16) | 0
          const v = c === 'x' ? r : (r & 0x3) | 0x8

          return v.toString(16)
        })
      }

      const treemap = () => d3.treemap().tile(tile)(root)

      const svg = d3
        .select('#tree-map-container')
        .append('svg')
        // .attr('viewBox', [0.5, -30.5, width, height + 30])
        .attr('class', 'treemap')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)

      let group = svg.append('g')

      group.call(render, treemap(data))

      function render (group, root) {
        const node = group
          .selectAll('g')
          .data(root.children.concat(root))
          .join('g')

        node
          .filter((d) => (d === root ? d.parent : d.children))
          .attr('cursor', 'pointer')
          .on('click', (event, d) => (d === root ? zoomout(root) : zoomin(d)))

        // node.append('title')
        //   .text(d => `${name(d)}\n${format(Number(d.data.marketValue))}`)

        node
          .append('rect')
          .attr('rx', 4)
          .attr('id', (d) => (d.leafUid = uuidv4()))
          .attr('fill', (d) =>
            d === root
              ? '#ccc'
              : d.children
                ? color(d.data.name)
                : color(d.parent.data.name)
          )
          .attr('stroke', 'var(--primary)')
          // .attr('stroke-width', 3)
          .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
          .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
          .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))

        node
          .append('clipPath')
          .attr('id', (d) => (d.clipUid = uuidv4()))
          .append('use')
          .attr('xlink:href', (d) => `#${d.leafUid}`)

        node
          .append('text')
          .attr('clip-path', (d) => `url(#${d.clipUid})`)
          .attr('font-weight', (d) => (d === root ? 'bold' : null))
          .selectAll('tspan')
          .data((d) =>
            (d === root ? name(d) : d.data.name)
              .split(/(?=[A-Z][^A-Z][A-Z])/g)
              .concat(`$${format(Number(d.data.marketValue))}M`)
          )
          .join('tspan')
          .attr('x', 3)
          .attr(
            'y',
            (d, i, nodes) =>
              `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`
          )
          // .attr('fill', '#FFF')
          .attr('fill', (d) => {
            return !d.includes('All Regions') ? '#fff' : '#000'
          })
          .attr('fill-opacity', (d, i, nodes) =>
            i === nodes.length - 1 ? 0.85 : null
          )
          .attr('font-weight', (d, i, nodes) =>
            i === nodes.length - 1 ? 'normal' : null
          )
          .text((d) => {
            return !d.includes('NaN') ? d : null
          })

        group.call(position, root)
      }

      function position (group, root) {
        group
          .selectAll('g')
          .attr('transform', (d) =>
            d === root ? 'translate(0,-50)' : `translate(${x(d.x0)},${y(d.y0)})`
          )
          .select('rect')
          .attr('width', (d) => (d === root ? width : x(d.x1) - x(d.x0)))
          .attr('height', (d) => (d === root ? 50 : y(d.y1) - y(d.y0)))
      }

      // When zooming in, draw the new nodes on top, and fade them in.
      function zoomin (d) {
        const group0 = group.attr('pointer-events', 'none')
        const group1 = (group = svg.append('g').call(render, d))

        x.domain([d.x0, d.x1])
        y.domain([d.y0, d.y1])

        svg
          .transition()
          .duration(750)
          .call((t) => group0.transition(t).remove().call(position, d.parent))
          .call((t) =>
            group1
              .transition(t)
              .attrTween('opacity', () => d3.interpolate(0, 1))
              .call(position, d)
          )
      }

      // When zooming out, draw the old nodes on top, and fade them out.
      function zoomout (d) {
        const group0 = group.attr('pointer-events', 'none')
        const group1 = (group = svg.insert('g', '*').call(render, d.parent))

        x.domain([d.parent.x0, d.parent.x1])
        y.domain([d.parent.y0, d.parent.y1])

        svg
          .transition()
          .duration(750)
          .call((t) =>
            group0
              .transition(t)
              .remove()
              .attrTween('opacity', () => d3.interpolate(1, 0))
              .call(position, d)
          )
          .call((t) => group1.transition(t).call(position, d.parent))
      }

      function tile (node, x0, y0, x1, y1) {
        d3.treemapBinary(node, 0, 0, width, height)
        for (const child of node.children) {
          child.x0 = x0 + (child.x0 / width) * (x1 - x0)
          child.x1 = x0 + (child.x1 / width) * (x1 - x0)
          child.y0 = y0 + (child.y0 / height) * (y1 - y0)
          child.y1 = y0 + (child.y1 / height) * (y1 - y0)
        }
      }

      function tooltipFun (event, d, type) {
        if (event.target.getAttribute('fill') !== '#ccc') {
          let data = {}
          switch (type) {
            case 'in':
              // eslint-disable-next-line no-case-declarations
              data = {
                '': d.data.name,
                'Market Value': `$${d.data.marketValue.toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}M`,
                'CAGR Growth': `${d.data.cagrGrowth.toFixed(1)}%`
              }
              break
          }
          tooltip(event, data, type)
        }
      }
      this.updateMapLoader(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.tree-map-outer-wrapper {
  min-height: 500px;
  height: 93%;
}

.regional-tree {
  height: 98%;
  position: relative;
}

.local-loader-container {
  position: absolute;
  display: flex;
  top: -2%;
  width: 100%;
  height: 102%;
  background: rgba(0, 0, 0, 0.85);
}

.legends-container {
    display: flex;
    flex: 1;
    padding: 5px 0;
    .legend-inside {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .legend {
        display: flex;
        align-items: center;
        margin-left: 10px;
        p {
          margin: 0px;
          color: var(--fourth-text-color);
          font-size: 12px;
          font-family: Roboto;
        }
        .colored-rect {
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 5px;
          border-radius: 8px;
        }
        .dashed-line {
          display: inline-block;
          border: none;
          border-top: 3px dashed #dd1d1b;
          height: 5px;
          width: 10px;
          margin-right: 5px;
        }
        .dashed-image {
          width: 20px;
        }
        .line {
          display: inline-block;
          border: none;
          border-top-style: solid;
          border-top-width: 3px;
          border-top-color: var(--tertiary-text-color);
          height: 5px;
          width: 10px;
          margin-right: 5px;
        }
      }
    }
    .legends-common {
      font-size: 12px;
      color: var(--fourth-text-color);
      display: flex;
      flex: 1;
      justify-content: center;
      .right-fix-legends {
        width: 311px;
        display: flex;
      }
      .left-legends {
        width: calc(100% - 311px);
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
      .seperator {
        display: inline;
        width: 1px;
        height: 30px;
        padding-left: 14px;
        border-right: 1px solid var(--split-color);
        justify-content: center;
      }
    }
  }
</style>
