<template>
  <div class="regional-map-container">
    <div class="button-group">
      <button class="zoom-in">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="invisible box"><path data-name="Rectangle 147750" fill="none" d="M0 0h12v12H0z"/></g><g data-name="plus_icon"><path data-name="Path 12673" d="M6.51 6.51l3.84.022a.541.541 0 00.532-.458.489.489 0 00-.511-.584l-3.864.004.003-3.865a.489.489 0 00-.585-.51.541.541 0 00-.458.53l.021 3.84-3.84-.017a.541.541 0 00-.532.458.489.489 0 00.51.583h3.86v3.861a.489.489 0 00.584.51.541.541 0 00.458-.532z" fill="#7f8fa4"/></g></svg>
      </button>
      <button class="zoom-out">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="invisible box"><path data-name="Rectangle 147750" fill="none" d="M0 0h12v12H0z"/></g><g data-name="icons Q2"><path data-name="Path 12673" d="M6.51 6.51l3.84.022a.541.541 0 00.532-.458.489.489 0 00-.511-.584l-3.864.004h-1.02l-3.839-.022a.541.541 0 00-.532.458.489.489 0 00.51.583h3.86z" fill="#7f8fa4"/></g></svg>
      </button>
    </div>
    <div class="map-loader" v-if="mapLoader">
      <!-- <div class="loader"></div> -->
      <div class="pin"></div>
      <div class="pulse"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3'
import tooltip from '@/util/tooltip'
import { regionColors } from '@/util/util'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'RegionalMap',
  props: ['marks'],
  data() {
    return {
      maxZoom: 40,
      observer: null,
      zoomConst: null,
      minRadius: 4,
      maxRadius: 40
    }
  },
  computed: {
    ...mapState('filters', ['selectedCountries', 'regionList']),
    ...mapState('tam', ['mapLoader', 'regionView', 'geoData']),
    maxMarketValue() {
      const marks = this.marks
      const marketValues = []
      for (const obj of marks) {
        marketValues.push(obj.marketValue)
      }
      return d3.max(marketValues)
    }
  },
  watch: {
    marks() {
      this.renderChart()
    },
    selectedCountries() {
      const svg = document.querySelector('.regional-map-container > svg')
      const zoomConst = d3.zoomTransform(svg)
      this.zoomConst = zoomConst
    },
    geoData() {
      this.renderChart()
    }
  },
  mounted() {
    this.resizeWindow()
  },
  methods: {
    ...mapActions('tam', ['updateMapLoader']),
    regionColors,
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow() {
      this.observer = new ResizeObserver(() => {
        // if (this.data.length) {
        if (d3.select('.regional-map-container').node() != null) {
          this.renderChart()
          // }
        }
      })
      this.observer.observe(document.querySelector('.regional-map-container'))
    },
    renderChart() {
      // The svg
      d3.selectAll('.regional-map-container > svg').remove()
      const svg = d3.select('.regional-map-container').append('svg')
      const g = svg.append('g')
      const width = +svg.node().clientWidth
      const height = +svg.node().clientHeight
      const maxZoom = this.maxZoom
      const zoomConst = this.zoomConst
      const regionView = this.regionView
      let active = null
      let prevActive = null

      const calcRadius = d3
        .scaleLinear()
        .domain([0, this.maxMarketValue])
        .range([this.minRadius, this.maxRadius])

      const zoom = d3
        .zoom()
        .scaleExtent([1, maxZoom])
        .translateExtent([
          [0, 0],
          [width, height]
        ])
        .on('zoom', zoomed)

      // Map and projection
      const projection = d3
        .geoMercator()
        .scale(100)
        .center([0, 45])
        .translate([width / 2, height / 2])

      projection.fitExtent(
        [
          [0, 0],
          [width, height]
        ],
        {
          type: 'Polygon',
          coordinates: [
            [
              [-179.999, 84],
              [-179.999, -60],
              [179.999, -60],
              [179.999, 84],
              [-179.999, 84]
            ]
          ]
        }
      )

      const marks = this.marks

      // Data and color scale
      const data = new Map()

      const topo = this.geoData

      // Draw the map
      const country = g
        .selectAll('path')
        .data(topo.features)
        .join('path')
        // draw each country
        .attr('d', d3.geoPath().projection(projection))
        .attr('opacity', 0.9)
        // set the color of each country
        .attr('fill', function (d) {
          d.total = data.get(d.id) || 0
          return 'var(--world-map-country)'
          // return '#ECECEC'
        })
        .attr('stroke', 'var(--world-map-country-border)')
        .attr('stroke-width', 0.5)
        .on('click', clickedCountry)

      const zoomContainer = svg

      const zoomInButton = d3.select('.zoom-in')
      const zoomOutButton = d3.select('.zoom-out')
      zoomInButton.on('click', () => {
        zoom.scaleBy(zoomContainer.transition().duration(750), 1.5)
      })
      zoomOutButton.on('click', () => {
        zoom.scaleBy(zoomContainer.transition().duration(750), 0.5)
      })

      zoomContainer
        .call(
          zoom.transform,
          zoomConst
            ? d3.zoomIdentity
                .translate(zoomConst.x, zoomConst.y)
                .scale(zoomConst.k)
            : d3.zoomIdentity
        )
        // .call(zoom.transform, zoomConst ? d3.zoomIdentity.translate(zoomConst.x, zoomConst.y).scale(zoomConst.k) : d3.zoomIdentity.translate(-width / 5, -height / 5).scale(1.4))
        .call(zoom) // delete this line to disable free zooming
      // .call(zoom.event)

      const markers = g.append('g').attr('class', 'markerGroup')
      markers
        .selectAll('.outerCircle')
        .data(marks)
        .enter()
        .append('g')
        .attr('class', (d, i) => `marker region-${i}`)
        .append('g')
        .attr('class', (d) => `regionMarker ${d.region_name}`)
        .style('display', (d) => {
          if (regionView !== 1 || (zoomConst && zoomConst.k > 2)) return 'none'
          else return 'block'
        })
        .append('circle')
        .attr('class', 'markerOuter')
        .attr('r', (d) => calcRadius(d.marketValue))
        .attr('cx', (d) => projection([d.long, d.lat])[0])
        .attr('cy', (d) => projection([d.long, d.lat])[1])
        .attr('fill', function (d, i) {
          return regionColors(i)
        })
        .style('opacity', 0.2)
        .on('mouseover', function (d) {})

      markers
        .selectAll('.regionMarker')
        .data(marks)
        .append('circle')
        .attr('class', 'markerInner')
        .attr('r', (d) => calcRadius(d.marketValue))
        .attr('cx', (d) => projection([d.long, d.lat])[0])
        .attr('cy', (d) => projection([d.long, d.lat])[1])
        .attr('fill', 'none')
        .style('opacity', 1)

      markers
        .selectAll('.regionMarker')
        .data(marks)
        .append('circle')
        .attr('class', 'markerCenter')
        .attr('r', 6)
        .attr('cx', (d) => projection([d.long, d.lat])[0])
        .attr('cy', (d) => projection([d.long, d.lat])[1])
        .attr('fill', function (d, i) {
          return regionColors(i)
        })
        .style('transition', '0.2s ease')

      markers
        .selectAll('.regionMarker')
        .on('mouseover', function (event, d) {
          d3.select(this).select('.markerCenter').attr('r', (d) => {
            if (Number(d3.select(this).select('.markerCenter').attr('r')) * 2 <= calcRadius(d.marketValue)) {
              return calcRadius(d.marketValue) / 2.5
            } else {
              return 8
            }
          })

          tooltipFun(event, d, 'in')
        })
        .on('mousemove', function (event, d) {
          tooltipFun(event, d, 'in')
        })
        .on('mouseleave', function (event, d) {
          d3.select(this).select('.markerCenter').attr('r', 6)

          tooltipFun(event, d, 'out')
        })
        .on('click', clicked)

      function clicked(event, d) {
        const x = d3.pointer(event)[0]
        const y = d3.pointer(event)[1]
        d3.pointer(event)
        event.stopPropagation()
        zoomContainer
          .transition()
          .duration(750)
          .call(
            zoom.transform,
            d3.zoomIdentity
              .translate(width / 2, height / 2)
              .scale(2.5)
              .translate(-x, -y)
          )
      }

      const countryMarkers = g.selectAll('.marker').data(marks).join()

      countryMarkers
        .selectAll('.countryMarker')
        .data((d) => d.children)
        .enter()
        .each(function (d) {
          return d
        })
        .append('g')
        .attr('class', (d, i) => `countryMarker country-${i} ${d.country_name}`)
        .style(
          'display',
          (zoomConst && zoomConst.k > 2) || regionView !== 1 ? 'block' : 'none'
        )
        .append('circle')
        .attr('class', 'markerOuter')
        .attr('r', (d) => calcRadius(d.marketValue))
        .attr('cx', (d) => projection([d.long, d.lat])[0])
        .attr('cy', (d) => projection([d.long, d.lat])[1])
        .attr('fill', function (d, i) {
          const regionId = d3.select(this).node().parentElement.parentElement.classList[1].split('-')[1]
          return regionColors(regionId)
        })
        .style('opacity', 0.2)

      countryMarkers
        .selectAll('.countryMarker')
        .data((d) => d.children)
        .join()
        .each(function (d) {
          return d
        })
        .append('circle')
        .attr('class', 'markerCenter')
        .attr('r', 2)
        .attr('cx', (d) => projection([d.long, d.lat])[0])
        .attr('cy', (d) => projection([d.long, d.lat])[1])
        .attr('fill', function (d, i) {
          const regionId = d3.select(this).node().parentElement.parentElement.classList[1].split('-')[1]
          return regionColors(regionId)
        })
        .style('transition', '0.2s ease')

      countryMarkers
        .selectAll('.countryMarker')
        .data((d) => d.children)
        .join()
        .each(function (d) {
          return d
        })
        .append('circle')
        .attr('class', 'markerInner')
        .attr('r', (d) => calcRadius(d.marketValue))
        .attr('cx', (d) => projection([d.long, d.lat])[0])
        .attr('cy', (d) => projection([d.long, d.lat])[1])
        .attr('fill', 'none')

      countryMarkers
        .selectAll('.countryMarker')
        .on('mouseover', function (event, d) {
          d3.select(this).select('.markerCenter').attr('r', (d) => { return 6 })

          tooltipFun(event, d, 'in')
        })
        .on('mousemove', function (event, d) {
          tooltipFun(event, d, 'in')
        })
        .on('mouseleave', function (event, d) {
          d3.select(this).select('.markerCenter').attr('r', 2)

          tooltipFun(event, d, 'out')
        })

      function clickedCountry(event, d) {
        prevActive = active
        if (active && active.node() === this) return reset()
        // country.transition().style('opacity', 0.7)
        active = d3.select(this).classed('active', true)
        if (prevActive) {
          prevActive.classed('active', false)
        }
        const [[x0, y0], [x1, y1]] = d3
          .geoPath()
          .projection(projection)
          .bounds(d)
        event.stopPropagation()
        // d3.select(this).transition().style('opacity', 1)
        zoomContainer
          .transition()
          .duration(750)
          .call(
            zoom.transform,
            d3.zoomIdentity
              .translate(width / 2, height / 2)
              .scale(
                Math.min(
                  15,
                  0.9 / Math.max((x1 - x0) / width, (y1 - y0) / height)
                )
              )
              .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
            d3.pointer(event, zoomContainer.node())
          )
      }

      function reset() {
        active.classed('active', false)
        active = d3.select(null)

        zoomContainer
          .transition()
          .duration(750)
          .call(
            zoom.transform,
            d3.zoomIdentity.translate(-width / 5, -height / 5).scale(1.4)
          )
      }

      function tooltipFun(event, d, type) {
        const fill = event.target.getAttribute('fill')
        const tooltipElem = document.querySelector('#common-tooltip')
        if (fill) {
          tooltipElem.style.setProperty('--cagr-tooltip-color', fill)
        }
        if (type === 'in') {
          tooltipElem.classList.add('cagr-tooltip')
        } else {
          tooltipElem.classList.remove('cagr-tooltip')
        }
        const html = `
        <div>
          <div> ${d.country_name ? d.country_name : d.region_name} </div>
          <div style="font-family: 'Quicksand'; font-size: 18px; font-weight: 500" > $${d.marketValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}M </div>
          <div class="tooltip-CAGR" style="font-family: 'Quicksand'; font-weight: 300; opacity: 0.9">
            <div> CAGR Growth ${d.cagrGrowth.toFixed(1)}% </div>
          </div>
        </div>
        `
        tooltip(event, html, type)
      }

      function zoomed (event) {
        const t = event.transform
        g.attr('transform', t)

        if (regionView !== 1) {
          d3.selectAll('.regionMarker').style('display', 'none')
          d3.selectAll('.countryMarker').style('display', 'block')
        } else {
          if (t.k <= 2) {
            d3.selectAll('.countryMarker').style('display', 'none')
            d3.selectAll('.regionMarker').style('display', 'block')
          }

          if (t.k > 2) {
            d3.selectAll('.regionMarker').style('display', 'none')
            d3.selectAll('.countryMarker').style('display', 'block')
          }
        }
      }
      this.updateMapLoader(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.regional-map-container {
  // min-height: 900px;
  height: 500px;
  position: relative;
}
::v-deep svg {
  margin: auto;
  width: 100%;
  height: 100%;
}

.background {
  fill: none;
  pointer-events: all;
}

::v-deep .active {
  opacity: 1 !important;
}

::v-deep .button-group {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  bottom: 20px;
  left: 20px;
  button {
    width: 32px;
    height: 32px;
    :where(svg g[data-name='plus_icon'], svg g[data-name='icons Q2']) > path {
      fill: white;
    }
  }
  .zoom-in {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    background: var(--user-profile-image-background-color);
  }
  .zoom-out {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: none;
    background: var(--user-profile-image-background-color);
  }
}

.pin {
  animation-name: pin;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  // background: #312783;
  background: #5e2bbc;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #ffe388;
  position: absolute;
  border-radius: 50%;
}
.pulse {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: 2;
  pointer-events: none;
}
.pulse:after {
  content: '';
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  // box-shadow: 0 0 1px 2px #312783;
  box-shadow: 0 0 1px 2px #5e2bbc;
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    -webkit-transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
  }
}

@keyframes pin {
  100% {
    top: 45%;
    bottom: 60px;
  }
}

.map-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 4px;
}
</style>
