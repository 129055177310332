<template>
  <div class="new-module-main">
    <div class="columns is-2 is-variable">
      <div class="column is-3">
        <div class="tile-body five-year-growth">
          <div class="local-loader-container" v-if="cagrLoader">
            <div class="inava-loader"></div>
          </div>
          <div
            class="not-found"
            v-if="(noData || noTamCagrData) && !cagrLoader"
          >
            <no-data-container-vue type="tam"></no-data-container-vue>
          </div>
          <div
            class="five-year-growth-header"
            v-if="!noData && !noTamCagrData && !cagrLoader"
          >
            5 Year CAGR: {{ totalCagr }}%
          </div>
          <div
            class="five-year-growth-data"
            v-if="!noData && !noTamCagrData && !cagrLoader"
          >
            <div class="current-year">
              <div class="year-title">
                Total Addressable Market
                {{ cagrGrowthData.length ? cagrGrowthData[0].year : '' }}
              </div>
              <div class="current-market-value">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="Group 1115" transform="translate(340.775 -50)"><rect data-name="Rectangle 147922" width="34" height="34" rx="4" transform="translate(-340.775 50)" fill="#3c90f5"/><g data-name="Layer 2"><g data-name="invisible box"><path data-name="Rectangle 147921" fill="none" d="M-335.775 55.001h24v24h-24z"/></g><g data-name="icons Q2"><path data-name="Path 13354" d="M-333.298 75.57a.952.952 0 01.952-.952h17.14a.952.952 0 01.952.952.952.952 0 01-.952.952h-17.14a.952.952 0 01-.952-.952zm4.761-10.474a.952.952 0 00-.952-.952.952.952 0 00-.952.952v7.618h1.9zm11.426-6.666a.952.952 0 00-.952-.952.952.952 0 00-.952.952v14.283h1.9zm-7.617 3.809a.952.952 0 00-.952-.952.952.952 0 00-.952.952v10.474h1.9zm3.809 4.285a.952.952 0 00-.952-.952.952.952 0 00-.952.952v6.189h1.9z" fill="#fff"/></g></g></g></svg> -->
                {{
                  cagrGrowthData.length
                    ? '$' +
                      cagrGrowthData[0].value.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    : '...'
                }}
              </div>
            </div>
            <hr class="tam-hr" />
            <div style="width: 100%; height: 90%">
              <cagr-growth-chart
                :data="cagrGrowthData.length ? cagrGrowthData.slice(1) : []"
                key="instance"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column is-5 is-vertical second-column">
        <div
          class="forecast-container"
          v-if="!noData && !noServiceAddressableData"
        >
          <forecast-card
            :forecastValues="serviceAddressableData"
            :chartNumber="1"
          />
        </div>
        <div
          class="forecast-container"
          v-if="!noData && !noPartServiceAddressableData"
        >
          <forecast-card
            :forecastValues="partServiceAddressableData"
            :chartNumber="2"
            :multiOptions="true"
            @onChange="onChangeForecastView"
          />
        </div>
        <div
          class="not-found"
          v-if="
            (noData || noServiceAddressableData) &&
            !forecastLoader.loader1 &&
            !forecastLoader.loader2
          "
        >
          <no-data-container-vue type="tam" />
        </div>
      </div>
      <div class="column is-4 third-column">
        <div
          class="not-found"
          v-if="(noData || noVendorMarketData) && !vendorLoader"
        >
          <no-data-container-vue type="tam" />
        </div>
        <div class="tile-body vendor-landscape" v-else>
          <div class="local-loader-container" v-if="vendorLoader">
            <div class="inava-loader"></div>
          </div>
          <!-- <div class="vendor-tile" style="height: 90%; border: 1px solid red;"> -->
          <div class="vendor-header is-flex">
            <div class="vendor-title">Vendor Landscape {{ years[0] }}</div>
            <div class="csat-toggle">
              <div class="vendor-csat-title">CSAT View</div>
              <toggle-button
                :toggle="csatView"
                @onChange="toggleHorizontalBarView"
              />
            </div>
          </div>
          <div class="remaining h-100" v-if="!noData && !noVendorMarketData">
            <horizontal-bar-chart
              :csatView="csatView"
              :data="vendorLandscape"
            />
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="tile is-12 is-child px-2">
      <ul class="tam-menu is-flex">
        <li
          class="tam-menu-item"
          @click="changeView(0)"
          :class="view === 0 && 'active border-bottom'"
        >
          Geographical Distribution
        </li>
        <li
          class="tam-menu-item"
          @click="changeView(1)"
          :class="view === 1 && 'active border-bottom'"
        >
          Market Segment Insights
        </li>
        <li
          class="tam-menu-item"
          @click="changeView(2)"
          :class="view === 2 && 'active border-bottom'"
        >
          Data Set
        </li>
        <li class="tam-menu-dropdown" v-if="view === 1">
          <div class="data-source-container">
            <single-selector-vue
              label="market_segment_by"
              placeholder="view by"
              :list="tamTabOptions"
              :selected="localSelected"
              :showRadio="true"
              :showLabel="false"
              :updateAnalytics="true"
              @onChange="onChangeOption"
            />
          </div>
        </li>
      </ul>
      <div class="tile is-12 mb-4">
        <!-- <div v-if="noData"> -->
        <no-data-container-vue
          v-if="noData || noGeographicalData || noTreeMapData"
          type="tam"
        />
        <!-- </div> -->
        <div class="tile-body view-container" v-else>
          <div
            class="local-loader-container"
            v-if="dataSetLoader && view === 2">
            <div class="inava-loader"></div>
          </div>
          <RegionalMap v-if="view === 0" :marks="geographicalData" />
          <RegionalTreeMap
            :data="treeMapData"
            :selected="dataType"
            v-if="view === 1"
          />
          <table v-if="view === 2" class="tam-table">
            <thead>
              <tr>
                <th @click="updateSort('region')">
                  <div class="header-block">
                    REGION
                    <div class="sort-container">
                      <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                          sortObj.type === 'asc' && sortObj.by === 'region'
                            ? 'active'
                            : ''
                        ]" v-bind:class="'rotate-sort'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 197.286L159.028 69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233 1.807-12.85 5.424L5.424 197.286C1.807 200.9 0 205.184 0 210.132s1.807 9.233 5.424 12.847c3.621 3.617 7.902 5.428 12.85 5.428h255.813c4.949 0 9.233-1.811 12.848-5.428 3.613-3.613 5.427-7.898 5.427-12.847s-1.814-9.232-5.427-12.846z"/></svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                          sortObj.type === 'desc' && sortObj.by === 'region'
                            ? 'active'
                            : ''
                        ]" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"/></svg>
                    </div>
                  </div>
                </th>
                <th @click="updateSort('verticals')">
                  <div class="header-block">
                    VERTICALS
                    <div class="sort-container">
                      <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                          sortObj.type === 'asc' && sortObj.by === 'verticals'
                            ? 'active'
                            : ''
                        ]" v-bind:class="'rotate-sort'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 197.286L159.028 69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233 1.807-12.85 5.424L5.424 197.286C1.807 200.9 0 205.184 0 210.132s1.807 9.233 5.424 12.847c3.621 3.617 7.902 5.428 12.85 5.428h255.813c4.949 0 9.233-1.811 12.848-5.428 3.613-3.613 5.427-7.898 5.427-12.847s-1.814-9.232-5.427-12.846z"/></svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                          sortObj.type === 'desc' && sortObj.by === 'verticals'
                            ? 'active'
                            : ''
                        ]" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"/></svg>
                    </div>
                  </div>
                </th>
                <th @click="updateSort('businessSize')">
                  <div class="header-block">
                    BUSINESS SIZE
                    <div class="sort-container">
                      <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                          sortObj.type === 'asc' &&
                          sortObj.by === 'businessSize'
                            ? 'active'
                            : ''
                        ]" v-bind:class="'rotate-sort'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 197.286L159.028 69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233 1.807-12.85 5.424L5.424 197.286C1.807 200.9 0 205.184 0 210.132s1.807 9.233 5.424 12.847c3.621 3.617 7.902 5.428 12.85 5.428h255.813c4.949 0 9.233-1.811 12.848-5.428 3.613-3.613 5.427-7.898 5.427-12.847s-1.814-9.232-5.427-12.846z"/></svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                          sortObj.type === 'desc' &&
                          sortObj.by === 'businessSize'
                            ? 'active'
                            : ''
                        ]" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"/></svg>
                    </div>
                  </div>
                </th>
                <th v-for="year in years" :key="year" @click="updateSort(year)">
                  <div class="header-block">
                    {{ year }}
                    <div class="sort-container">
                      <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                          sortObj.type === 'asc' && sortObj.by == year
                            ? 'active'
                            : ''
                        ]" v-bind:class="'rotate-sort'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 197.286L159.028 69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233 1.807-12.85 5.424L5.424 197.286C1.807 200.9 0 205.184 0 210.132s1.807 9.233 5.424 12.847c3.621 3.617 7.902 5.428 12.85 5.428h255.813c4.949 0 9.233-1.811 12.848-5.428 3.613-3.613 5.427-7.898 5.427-12.847s-1.814-9.232-5.427-12.846z"/></svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                          sortObj.type === 'desc' && sortObj.by == year
                            ? 'active'
                            : ''
                        ]" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"/></svg>
                    </div>
                  </div>
                </th>
                <th @click="updateSort('cagr')">
                  <div class="header-block">
                    5 Year CAGR
                    <div class="sort-container">
                      <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                          sortObj.type === 'asc' && sortObj.by === 'cagr'
                            ? 'active'
                            : ''
                        ]" v-bind:class="'rotate-sort'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 197.286L159.028 69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233 1.807-12.85 5.424L5.424 197.286C1.807 200.9 0 205.184 0 210.132s1.807 9.233 5.424 12.847c3.621 3.617 7.902 5.428 12.85 5.428h255.813c4.949 0 9.233-1.811 12.848-5.428 3.613-3.613 5.427-7.898 5.427-12.847s-1.814-9.232-5.427-12.846z"/></svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                          sortObj.type === 'desc' && sortObj.by === 'cagr'
                            ? 'active'
                            : ''
                        ]" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"/></svg>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in tableData" :key="row[years[0]]">
                <td>
                  {{ row.region }}
                </td>
                <td>{{ row.verticals }}</td>
                <td>{{ row.businessSize }}</td>
                <td
                  v-for="year in years"
                  :key="year"
                  class="dataset-market-values"
                >
                  {{
                    Number(row[year]).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  }}
                </td>
                <td class="dataset-cagr-values">{{ row.cagr }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from '../../components/Common/ToggleButton.vue'
import RegionalTreeMap from './RegionalTreeMap.vue'
import HorizontalBarChart from './HorizontalBarChart.vue'
import RegionalMap from './RegionalMap.vue'
import CagrGrowthChart from './CagrGrowthChart.vue'
// import DropdownWrap from '../../components/Common/DropdownWrap.vue'
import ForecastCard from '../../components/TAM/ForecastCard.vue'
import SingleSelectorVue from '../../components/Dropdowns/SingleSelector.vue'
import NoDataContainerVue from '../../components/Common/NoDataContainer/NoDataContainer.vue'
import { mapActions, mapState } from 'vuex'
import { metaTitle, analyticsEvents, tamTabOptions } from '../../constant/data'
import moment from 'moment'
export default {
  name: 'TAMModule',
  metaInfo() {
    return {
      title: metaTitle.tam
    }
  },
  components: {
    RegionalMap,
    ToggleButton,
    HorizontalBarChart,
    RegionalTreeMap,
    CagrGrowthChart,
    SingleSelectorVue,
    // DropdownWrap,
    ForecastCard,
    NoDataContainerVue
  },
  data() {
    return {
      tamTabOptions,
      vendorLandscape: [],
      dropdownOpen: false,
      localSelected: {},
      view: 0,
      csatView: false,
      serviceAddressablePart: 2,
      noData: false,
      noTamCagrData: false,
      noServiceAddressableData: false,
      noPartServiceAddressableData: false,
      noVendorMarketData: false,
      noGeographicalData: false,
      noTreeMapData: false,
      sortObj: {
        by: null,
        type: 'desc'
      },
      viewList: [
        {
          name: 'By Geography',
          value: 0
        },
        {
          name: 'By Verticals',
          value: 1
        },
        {
          name: 'By Size Band',
          value: 2
        }
      ]
    }
  },
  computed: {
    ...mapState('filters', [
      'selectedCategories',
      'selectedIndustries',
      'selectedCompanies',
      'subCategories',
      'companies',
      'selectedCountries',
      'industries',
      'categoryList'
    ]),
    ...mapState('tam', [
      'cagrGrowthData',
      'vendorLandscapeData',
      'marketSegmentInsightData',
      'serviceAddressableData',
      'partServiceAddressableData',
      'tableData',
      'totalCagr',
      'years',
      'geographicalData',
      'cagrLoader',
      'vendorLoader',
      'mapLoader',
      'dataSetLoader',
      'regionView',
      'forecastLoader',
      'geoData'
    ]),
    ...mapState('user', ['userDetails']),
    dataType() {
      return this.localSelected.id
    },
    treeMapData() {
      const obj = {
        name:
          this.regionView !== 1 && this.localSelected.id === 0
            ? 'All Regions'
            : 'Origin',
        children:
          this.regionView !== 1 && this.localSelected.id === 0
            ? this.geographicalData
            : this.marketSegmentInsightData
      }
      return obj
    },
    // getName () {
    //   return this.viewList.find((option) => option.value === this.localSelected).name
    // },
    currentYear() {
      return moment().year()
    },
    instance() {
      return moment().format('MM/DD/YYYY HH:mm:ss')
    },
    watchable() {
      return (
        JSON.stringify(this.subCategories) +
        JSON.stringify(this.selectedCountries) +
        JSON.stringify(this.selectedIndustries) +
        JSON.stringify(this.selectedCompanies) +
        JSON.stringify(this.years) 
      )
    }
  },
  watch: {
    watchable() {
      if (this.watchable.length) {
        this.getData()
      }
    },
    selectedCompanies() {
      if (
        this.selectedCompanies.length &&
        this.selectedCompanies.length < this.companies.length
      ) {
        this.serviceAddressablePart = this.selectedCompanies[0].id
      }
    },
    localSelected() {
      this.getTreeData()
    },
    years() {
      this.sortObj.by = this.years[0]
    },
    csatView() {
      this.vendorLandscape = this.csatView
        ? this.vendorLandscapeData.vendorCsat
        : this.vendorLandscapeData.vendorMarket
    },
    vendorLandscapeData() {
      this.vendorLandscape = this.csatView
        ? this.vendorLandscapeData.vendorCsat
        : this.vendorLandscapeData.vendorMarket
      this.updateVendorLoader(false)
      this.noVendorMarketData = !this.vendorLandscapeData.vendorMarket.length
    },
    sortObj: {
      handler() {
        this.updateDataSetSort(this.sortObj)
      },
      deep: true
    },
    cagrGrowthData() {
      this.noTamCagrData = !this.cagrGrowthData.length
    },
    serviceAddressableData() {
      this.noServiceAddressableData = !this.serviceAddressableData.length
    },
    partServiceAddressableData() {
      this.noPartServiceAddressableData =
        !this.partServiceAddressableData.length
    },
    geographicalData() {
      this.noGeographicalData = !this.geographicalData.length
    }
  },
  mounted() {
    this.sortObj.by = this.years[0]
    this.getGeoData()
    this.localSelected = this.tamTabOptions[0]
    this.updateCagrLoader(true)
    this.updateVendorLoader(true)
    this.updateForecastLoader(true)
    this.updateMapLoader(true)
  },
  methods: {
    ...mapActions('tam', [
      'getCagrGrowthData',
      'getVendorLandscapeData',
      'getServiceAddressableData',
      'getMarketSegmentInsightData',
      'getDataSet',
      'getYears',
      'updateDataSetSort',
      'getGeographicalData',
      'updateMapLoader',
      'updateCagrLoader',
      'updateVendorLoader',
      'updateForecastLoader',
      'getYearlyCagrGrowthData',
      'getGeoData'
    ]),
    ...mapActions('filters', [
      'updateSelectedNestedCategories',
      'updateSelectedCategory'
    ]),
    ...mapActions('analytics', ['updateEvent']),
    moment,
    getApiParams() {
      return {
        years: this.years,
        baseYear: this.years[0],
        industries: this.selectedIndustries.map((item) => item.id),
        allIndustries: this.industries.map((item) => item.id),
        companySize: this.selectedCompanies.map((item) => item.id),
        companies: this.companies.map((item) => item.id),
        startTime: moment(this.years[0], 'yyyy').startOf('year'),
        endTime: moment(this.years[0], 'yyyy').endOf('year'),
        categories: this.subCategories,
        subscriptionId: this.selectedCategories.subscriptionId,
        countries: this.selectedCountries.map((item) => item.country_id),
        groupBy: 'product',
        serviceAddressablePart: [this.serviceAddressablePart],
        sort: this.sortObj
      }
    },
    async getData() {
      if (
        this.selectedCategories != null &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.selectedCountries.length &&
        this.selectedIndustries.length &&
        this.years &&
        this.serviceAddressablePart 
      ) {
        this.noData = false
        const data = this.getApiParams()
        this.getVendorLandscapeData({
          ...data,
          industries: this.industries.map((item) => item.id)
        })
        this.getGeographicalData(data)
        this.getYearlyCagrGrowthData(data)
        this.getMarketSegmentInsightData({
          ...data,
          groupByOption: this.localSelected.id
        })
        this.getDataSet(data)
      } else {
        this.noData = true
      }
    },
    getTreeData() {
      if (
        this.selectedCategories != null &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.selectedIndustries.length &&
        this.selectedCountries.length &&
        this.years &&
        this.sortObj.by
      ) {
        const data = this.getApiParams()
        this.getMarketSegmentInsightData({
          ...data,
          groupByOption: this.localSelected.id
        })
      } else {
        this.noData = true
      }
    },
    changeView(index) {
      if (this.view !== index) {
        this.updateEvent({
          userId: this.userDetails.userId,
          event: 'click',
          label: analyticsEvents.tabChange,
          pageUrl: window.location.href
        })
      }
      this.view = index
    },
    onChangeOption(value) {
      this.localSelected = value
    },
    onClickOutside(event) {
      this.dropdownOpen = false
    },
    openViewDropdown() {
      this.dropdownOpen = !this.dropdownOpen
    },
    toggleHorizontalBarView() {
      this.csatView = !this.csatView
    },
    onChangeForecastView(payload) {
      if (
        this.selectedCategories != null &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.selectedIndustries.length &&
        this.years
      ) {
        this.serviceAddressablePart = payload
        const data = this.getApiParams()
        this.getServiceAddressableData({
          ...data,
          companySize: [payload],
          part: true
        })
      }
    },
    updateSort(by) {
      if (this.sortObj.by === by) {
        if (this.sortObj.type === 'asc') {
          this.sortObj.type = 'desc'
        } else {
          this.sortObj.type = 'asc'
        }
      } else {
        this.sortObj.by = by
        this.sortObj.type = 'asc'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-module-main {
  margin-bottom: 35px;
  .remaining {
    width: 100%;
    height: 90%;
  }
}

.tile-body {
  background: var(--primary);
  // background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: block;
  width: 100%;
  // border: var(--tertiary-border-color);
}

.five-year-growth,
.vendor-landscape {
  height: 100%;
  min-height: 300px;
  position: relative;
}

.five-year-growth {
  // background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.five-year-growth-data {
  padding: 20px;
  height: 70%;
}

.five-year-growth-header {
  color: var(--five-year-growth-color);
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: var(--five-year-growth-background);
  opacity: 1;
  padding: 14px 18px;
  font-family: Quicksand;
}

.year-title {
  color: var(--forecast-card-title);
  font-family: Quicksand;
  font-size: 14px;
  text-align: left;
  // font: normal normal medium 16px/20px Quicksand;
  letter-spacing: 0px;
  // color: #303030;
  opacity: 1;
  font-family: Quicksand;
  font-weight: 500;
}

.current-market-value {
  text-align: left;
  font-size: 24px;
  font-family: Quicksand;
  font-weight: 900;
  // font: normal normal bold 28px/35px Quicksand;
  letter-spacing: 0px;
  color: var(--market-value-font-color);
  opacity: 1;
  align-items: center;
  font-family: Quicksand;
  padding: 5px 0 15px;
  img {
    margin-right: 10px;
  }
}

.tam-hr {
  margin: 0 0 10px;
  height: 1px;
  background-color: var(--tam-hr);
}

.tam-menu {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.tam-menu-item {
  margin-right: 20px;
  font-family: Quicksand;
  color: var(--vs-color);
  font-weight: 500;
  padding-bottom: 2px;
  margin-bottom: 10px;
  // color: #8e8e8e;
  cursor: pointer;
  &:hover {
    color: var(--tam-menu-item-font-color);

    // color: #000000;
  }
}

.active {
  color: var(--tam-menu-item-font-color);
  // color: #000000;#8383FF
}

.border-bottom {
  border-bottom: 2px solid var(--tam-menu-item-border-color);
}

.vendor-tile,
.vendor-landscape {
  padding: 10px 15px 0;
}

.vendor-title {
  // color: var(--description);
  font-family: Quicksand, sans-serif;
  font-size: 12px;
  letter-spacing: 0px;
  font-weight: 500;
  color: var(--forecast-card-title);
  opacity: 1;
}

.tam-table {
  position: relative;
  background: var(--primary);
  font-family: 'Quicksand', sans-serif;
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  // border: var(--tertiary-border-color);

  td,
  th {
    padding: 10px 10px;
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 400;
    letter-spacing: 0px;
    color: var(--tam-dataset-text-color);
    opacity: 1;
    border: var(--tertiary-border-color);
  }
  .dataset-market-values,
  .dataset-cagr-values {
    color: var(--dataset-special-values-color);
  }
  th {
    font-weight: normal;
    font-size: 13px;
    // color: #eff3f6b2;
    color: var(--tam-dataset-header);
    letter-spacing: 0px;
    // color: #2a2a2a;
    opacity: 1;
    border: none;
    // padding-top: 25px;
    vertical-align: middle;
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      padding: 10px 5px;
      .header-block {
        justify-content: flex-end;
        width: auto;
      }
    }
    &:hover {
      cursor: pointer;
    }
    .header-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: max-content;
      svg {
        outline: none;
        width: 8px;
        // fill: var(--secondary-text-color);
        fill: rgba(128, 128, 128, 0.5);
        height: 8px;
        display: flex;
      }
      .active {
        // fill: rgba(128, 128, 128, 0.5);
        fill: var(--secondary-text-color);
      }
      .sort-container {
        display: flex;
        flex-direction: column;
        padding: 0px 5px 0px 10px;
      }
    }
  }
  tbody tr {
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7),
    td:nth-child(8),
    td:nth-child(9) {
      text-align: right;
    }
  }
}

.view-container {
  position: relative;
  // overflow: auto;
  .local-loader-container {
    background-color: rgba(0, 0, 0, 0.85);
  }
}

.dropdown-container {
  display: flex;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  align-items: center;
  p {
    font-size: 14px;
    font-family: 'Quicksand';
    color: var(--fifth-text-color);
    // padding-left: 5px;
  }
  input {
    margin: 0px 5px 0px 15px;
  }
}

::v-deep .view-dropdown-button {
  border: var(--secondary-border-color);
  background: transparent;
  display: flex;
  align-items: center;
  outline: none;
  border-radius: 4px;
  position: relative;
  justify-content: flex-start;
  height: 36px;
  padding: 10px;
  width: 250px;
  .inside-dropdown-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p,
    svg {
      display: flex;
    }
  }
  span {
    width: 100%;
  }
}

.dropdown {
  position: absolute;
  background: var(--dropdown-backgroud);
  box-shadow: 0px 6px 18px #00000033;
  border-radius: 4px;
  right: 0px;
  width: 250px;
  padding: 0px 0px 0px 0px;
  z-index: 100000;
  top: 105%;
  overflow: hidden;
  .dropdown-options {
    color: var(--description);
    width: 100%;
    // padding-left: 5px;
    .option {
      // padding: 6px 20px 6px 20px;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      &:last-child {
        label {
          padding-bottom: 10px;
        }
      }
      input {
        margin: 0;
        margin-right: 5px;
      }
      p,
      label {
        font-size: 14px;
        font-family: Roboto;
        letter-spacing: 0px;
        cursor: pointer;
        width: 100%;
      }
      p {
        padding-left: 5px;
      }
      label {
        display: flex;
        align-items: center;
        padding: 6px 20px 6px 20px;
        .radio-checkmark {
          height: 15px;
          width: 15px;
        }
      }
      svg {
        fill: #c2c7de;
      }
      &:hover {
        background: var(--dropdown-background-hover);
      }
    }
    .active {
      background: var(--dropdown-background-hover);
    }
    .active svg {
      fill: #2780eb;
    }
  }
}

.tam-menu-dropdown {
  display: flex;
  margin-left: auto;
}

.second-column {
  // border: 1px solid yellow;
  height: fit-content;
  display: flex;
  flex-direction: column;
  height: auto;
}

.forecast-container {
  margin-bottom: 15px;
  display: flex;
  flex-grow: 1;
  &:last-child {
    margin-bottom: 0;
  }
}

.csat-toggle {
  display: flex;
  font-size: 12px;
  color: var(--forecast-card-title);
  font-weight: 500;
  margin-left: auto;
  margin-right: 10px;
  align-items: center;
  font-family: Quicksand;

  // text-align: right;
  // font: normal normal medium 16px/20px Quicksand;
  letter-spacing: 0px;
  // color: #424242;
  opacity: 1;
  .vendor-csat-title {
    margin-left: 5px;
  }
}

.local-loader-container {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary);
  // background: rgba(0,0,0,0.85);
  z-index: 9;
}

.not-found {
  height: 100%;
}

::v-deep .data-source-header {
  background: var(--dropdown-background-cat) !important;

  border: 0.8px solid var(--voc-bar-background-color) !important;
  justify-content: flex-start;
  span {
    width: inherit;
  }
  .ellipsis {
    overflow: visible !important;
    text-overflow: unset !important;
    color: var(--secondary-text-color) !important;
  }
}

::v-deep .top-label {
  color: #7f8fa4 !important;
}

::v-deep .data-source-abs {
  width: 100%;
  background-color: var(--primary) !important;
  .custom-checkbox {
    p {
      color: var(--secondary-text-color) !important;
    }
  }
  .custom-radio {
    color: var(--secondary-text-color) !important;
    p {
      display: flex;
      flex: 1;
      padding-left: 5px;
    }
    svg {
      margin-left: 10px;
    }
  }
  li:hover {
    background-color: var(--hover-local-dropdown-background) !important;
  }
}

@media only screen and (max-width: 1200px) {
  .current-market-value {
    img {
      display: none;
    }
  }
}
</style>
