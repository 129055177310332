<template>
  <div
    class="tile-body service-addr-market"
    :id="'forecast-card-' + chartNumber">
    <div
      class="local-loader-container"
      v-if="forecastLoader['loader' + chartNumber]">
      <div class="inava-loader"></div>
    </div>
    <div class="forecast-card-title" :class="multiOptions && 'multiOption'">
      <div
        v-if="multiOptions"
        class="dropdown-arrow"
        @mouseover="openViewDropdown" @mouseleave="closeViewDropdown">
        <dropdown-wrap @onClickOutside="onClickOutside">
          <span>
            {{ title }} {{ forecastValues[0] && forecastValues[0].year }}
          </span>
          <span>
            <img src="../../assets/Arrow-down.svg" alt="dropdown-arrow" />
          </span>
        </dropdown-wrap>
        <div v-if="dropdownOpen" class="dropdown">
          <div class="dropdown-container">
            <div class="dropdown-options">
              <div class="option" @click="onChangeOption(1)">
                <label for="rating" class="custom-radio">
                  <input type="radio" id="rating" :checked="localSelected == 1" />
                  <div class="radio-checkmark"></div>
                  <p>Small and Medium Enterprise</p>
                </label>
              </div>
              <div class="option" @click="onChangeOption(2)">
                <label for="reaction" class="custom-radio">
                  <input
                    type="radio"
                    id="reaction"
                    :checked="localSelected == 2" />
                  <div class="radio-checkmark"></div>
                  <p>Large Enterprise</p>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span v-else>
        {{ title }} {{ forecastValues[0] && forecastValues[0].year }}
      </span>
    </div>

    <div class="is-flex">
      <div class="current-market-value">
        ${{
          forecastValues.length
            ? forecastValues[0].value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) + 'M'
            : 0
        }}
        <img
          src="../../assets/info-new.svg"
          class="new-info-icon"
          alt="info-icon"
          @mouseenter="tooltip($event, text, 'in')"
          @mousemove="tooltip($event, text, 'in')"
          @mouseout="tooltip($event, text, 'out')" />
      </div>
      <div style="width: 30%; margin-left: auto">
        <line-chart
          v-if="forecastValues && forecastValues.length"
          :id="'line-chart' + chartNumber"
          :data="
            forecastValues.map((d) => {
              return {
                date: moment(d.year, 'YYYY').format('YYYY-MM-DD'),
                value: d.value
              }
            })
          " />
      </div>
      <div class="cagr-growth-data">
        CAGR Growth
        <span class="cagr-growth-value">{{ totalCAGRGrowthEstimate }}%</span>
      </div>
    </div>
    <hr class="tam-hr" />
    <div class="future-sam columns is-multiline is-mobile">
      <div
        class="future-sam-item column"
        v-for="(object, index) of forecastValues.slice(1)"
        :key="index">
        <div class="future-year">{{ object.year }}</div>
        <div class="future-year-value">
          ${{
            object.value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }}M
        </div>
        <div
          class="value-percent-diff"
          :class="object.yoy >= 0 ? 'growth' : 'decline'"
          v-if="object.yoy">
          <div :class="object.yoy >= 0 ? 'arrow-up' : 'arrow-down'"></div>
          {{ object.yoy }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '../../views/TAM/LineChart.vue'
import moment from 'moment'
import tooltip from '../../util/tooltip'
import DropdownWrap from '../Common/DropdownWrap.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'ForecastCard',
  components: {
    LineChart,
    DropdownWrap
  },
  props: {
    multiOptions: Boolean,
    forecastValues: {
      type: Array,
      default () {
        return []
      }
    },
    chartNumber: Number
  },
  data () {
    return {
      localSelected: 2,
      dropdownOpen: false
    }
  },
  computed: {
    ...mapState('tam', [
      'forecastLoader',
      'cagrGrowthData',
      'serviceAddrCAGREstimate',
      'partServiceAddrCAGREstimate'
    ]),
    ...mapState('filters', ['companies', 'selectedCompanies']),
    totalCAGRGrowthEstimate () {
      if (this.multiOptions) {
        return this.partServiceAddrCAGREstimate
      }
      return this.serviceAddrCAGREstimate
    },
    title () {
      let title = ''
      if (this.multiOptions) {
        if (this.localSelected === 1) {
          title = 'Small and Medium Enterprise'
        } else {
          title = 'Large Enterprise'
        }
      } else {
        title = 'Service Addressable Market'
      }
      return title
    },
    text () {
      const share = (
        (this.forecastValues[0].value / this.cagrGrowthData[0].value) *
        100
      ).toFixed(1)
      const text = `${share}% of Total Addressable Market`
      return text
    }
  },
  watch: {
    localSelected (newValue, oldValue) {
      this.$emit('onChange', newValue)
    },
    forecastValues () {
      this.updateForecastLoader({ loader: false, id: this.chartNumber })
    },
    selectedCompanies () {
      if (this.selectedCompanies.length < this.companies.length) {
        this.localSelected = this.selectedCompanies[0].id
      }
    }
  },
  mounted () {
    if (this.selectedCompanies.length) {
      this.localSelected = this.selectedCompanies[0].id
    }
  },
  methods: {
    ...mapActions('tam', ['updateForecastLoader']),
    moment,
    tooltip,
    openViewDropdown () {
      if (this.multiOptions && this.selectedCompanies.length > 1) {
        this.dropdownOpen = true
      }
    },
    closeViewDropdown () {
      if (this.multiOptions && this.selectedCompanies.length > 1) {
        this.dropdownOpen = false
      }
    },
    onClickOutside () {
      this.dropdownOpen = false
    },
    onChangeOption (value) {
      this.localSelected = value
    }
  }
}
</script>

<style lang="scss" scoped>
.service-addr-market {
  padding: 10px 15px;
  position: relative;
}

.forecast-card-title {
  color: var(--forecast-card-title);
  font-family: Quicksand;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  width: fit-content;

  .dropdown-arrow {
    cursor: pointer;
    // width: 220px;
    border: 1px solid var(--forecast-dropdown-border-color);
    border-radius: 4px;
    opacity: 1;
    padding: 6px;
    padding-right: 10px;
    right: 10px;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      color: var(--forecast-dropdown-font-color);
      font-weight: 600;
    }
    span:has(img) {
      margin-left: 10px;
    }

    // float: right;
    // width: 100px;
  }
}

.dropdown {
  position: absolute;
  box-shadow: 0px 3px 6px 0 #00000029;
  left: 0px;
  width: 100%;
  margin-top: 2px;
  border: 1px solid var(--dropdown-border-color);
  // width: max-content;
  padding: 0px 0px 0px 0px;
  z-index: 10;
  top: 95%;
  overflow: hidden;
  .dropdown-options {
    color: var(--forecast-dropdown-option-font-color);
    background: var(--local-dropdown-background);
    // background: blue;
    width: 100%;
    border-radius: 0;
    // padding-left: 5px;
    .option {
      // padding: 6px 20px 6px 20px;
      display: flex;
      font-weight: 600;
      // justify-content: space-between;
      align-items: center;
      &:last-child {
        label {
          padding-bottom: 10px;
        }
      }
      input {
        margin: 0;
        margin-right: 5px;
      }
      p,
      label {
        font-size: 10px;
        color: var(--forecast-dropdown-option-font-color);
        font-family: Quicksand;
        letter-spacing: 0px;
        cursor: pointer;
        opacity: 0.8;
        width: 100%;
      }
      p {
        padding-left: 5px;
      }
      label {
        display: flex;
        align-items: center;
        padding: 6px 10px 6px 10px;
      }
      svg {
        fill: #c2c7de;
      }
      &:hover {
        background: var(--hover-local-dropdown-background);
        p {
          color: var(--hover-forecast-dropdown-option-font-color);
          opacity: 1;
        }
      }
    }
  }
}

.multiOption {
  cursor: pointer;
}

.current-market-value {
  // color: var(--secondary-text-color);
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  letter-spacing: 0px;
  color: var(--market-value-font-color);
  opacity: 1;
  display: flex;
  align-items: center;
  font-family: 'Quicksand', sans-serif;
  padding: 5px 0 15px;
  img {
    margin-right: 10px;
  }
}

.new-info-icon {
  margin-left: 5px;
}

.future-year {
  font-size: 12px;
  color: var(--forecast-card-title);
  font-family: Quicksand;
  font-weight: 500;
  // opacity: 0.7;
}

.future-year-value {
  font-size: 14px;
  font-family: Quicksand;
  font-weight: bold;
  color: var(--future-market-value);
}

.future-sam {
  justify-content: center;
  justify-content: space-evenly;
}

.future-sam-item {
  margin: 5px 0 0;
  padding: 0px 15px;
  display: flex;
  width: 100%;
  flex-direction: column;
  border-right: 1px solid var(--tam-hr);
}

@media only screen and (min-width: 1400px) {
  .future-sam-item:last-child {
    border: none;
  }
}

.tam-hr {
  margin: 0 0 10px;
  height: 1px;
  background-color: var(--tam-hr);
}

.cagr-growth-data {
  width: 10%;
  color: var(--description);
  font-family: 'Quicksand';
  font-size: 10px;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
}

.cagr-growth-value {
  color: #00c0c7;
  color: var(--green);
  font-size: 14px;
  font-family: 'Quicksand';
  font-weight: 500;
  opacity: 1;
}

.value-percent-diff {
  font-size: 10px;
  font-weight: bold;
  font-family: 'Quicksand';
  display: flex;
  align-items: center;
  :where(.arrow-down, .arrow-up) {
    margin-right: 3px;
  }
}

.growth {
  // color: var(--green);
  color: #00ab17;
}

.decline {
  color: var(--yellow);
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--green);
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--yellow);
}

.dropdown-container {
  display: flex;
  cursor: pointer;
  width: 100%;
  position: relative;
  align-items: center;
  p {
    font-size: 14px;
    font-family: 'Quicksand';
    color: var(--fifth-text-color);
    // padding-left: 5px;
  }
  input {
    margin: 0px 5px 0px 15px;
  }
}
.local-loader-container {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary);
  // background: rgba(0,0,0,0.85);
  border-radius: 4px;
  z-index: 5;
}
</style>
