<template>
  <div id="cagr-growth-chart-container"></div>
</template>

<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3'

export default {
  name: 'CAGRGrowthChart',
  props: {
    data: {
      type: Array
    }
  },
  data() {
    return {
      observer: null
    }
  },
  watch: {
    data() {
      this.renderChart()
    }
  },
  mounted() {
    this.resizeWindow()
  },
  methods: {
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow() {
      this.observer = new ResizeObserver(() => {
        if (this.data.length) {
          if (d3.select('#cagr-growth-chart-container').node() != null) {
            this.renderChart()
          }
        }
      })
      this.observer.observe(
        document.querySelector('#cagr-growth-chart-container')
      )
    },
    renderChart() {
      d3.selectAll('#cagr-growth-chart-container > *').remove()
      // set the dimensions and margins of the graph
      const margin = { top: 20, right: 0, bottom: -45, left: 0 }
      const container = document.querySelector('#cagr-growth-chart-container')
      const width = container.clientWidth - margin.left - margin.right
      const height = container.clientHeight - margin.top - margin.bottom

      // append the svg object to the body of the page
      const svg = d3
        .select('#cagr-growth-chart-container')
        .append('svg')
        .attr('id', 'cagr-growth-chart')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)

      function createChart(data) {
        // Add X axis
        const maxDomain = d3.max(data, (d) => d.value)
        const x = d3.scaleLinear().domain([0, maxDomain]).range([0, width])
        svg
          .append('g')
          .attr('class', 'x-axis')
          .attr(
            'transform',
            `translate(0, ${height - margin.top - margin.bottom})`
          )
          // .call(d3.axisBottom(x).ticks(5))
          .selectAll('text')
          .attr('transform', 'translate(-10,0)rotate(-45)')
          .style('text-anchor', 'end')

        // Y axis
        const y = d3
          .scaleBand()
          .range([0, height])
          .domain(data.map((d) => d.year))
          .padding(0.1)
        svg
          .append('g')
          .attr('class', 'y-axis')
          .call(d3.axisLeft(y))
          .selectAll('.tick text')
          .attr('fill', 'var(--description)')
          .remove()

        svg.select('.y-axis').selectAll('.tick line').remove()

        svg.select('.y-axis').select('.domain').attr('d', 'M 0,0')

        // svg.select('.x-axis')
        //   .selectAll('.tick')
        //   .selectAll('text')
        // .remove()

        // Bars
        svg
          .selectAll('myRect')
          .data(data)
          .join('rect')
          .attr('x', x(0))
          .attr('y', (d) => y(d.year))
          .attr('width', (d) => width)
          .attr('height', 11)
          // .attr('height', y.bandwidth())
          .attr('fill', 'var(--dot-plot-label-container)')

        svg
          .selectAll('myRect')
          .data(data)
          .join('rect')
          .attr('x', x(0))
          .attr('y', (d) => y(d.year))
          .attr('width', (d) => x(d.value))
          .attr('height', 11)
          // .attr('height', y.bandwidth())
          .attr('fill', 'var(--cagr-bar-color)')

        svg.selectAll('rect').attr('rx', function (d) {
          return d3.select(this).node().getBBox().height / 2
        })

        svg
          .selectAll('yearText')
          .data(data)
          .join('text')
          .text(function (d) {
            return d.year
          })
          .attr('class', 'year-text')
          .attr('y', (d) => y(d.year) - 10)
          .attr('fill', 'var(--description)')
          .style('font-family', 'Quicksand')
          .style('font-size', '12px')
          .attr('font-weight', 500)

        svg
          .selectAll('valueText')
          .data(data)
          .join('text')
          .text(function (d) {
            return `$${d.value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}M`
          })
          .attr('text-anchor', 'end')
          .attr('class', 'value-text')
          .attr('x', width)
          .attr('y', (d) => y(d.year) - 10)
          .attr('fill', 'var(--market-value-font-color)')
          .style('font-family', 'Quicksand')
          .style('font-size', '12px')
          .attr('font-weight', 600)

        d3.select('#cagr-growth-chart').attr(
          'height',
          `${svg.node().getBoundingClientRect().height + 5}`
        )
      }
      createChart(this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
#cagr-growth-chart-container {
  width: 100%;
  height: 100%;
}
</style>
