<template>
  <div id="tam-barchart"></div>
</template>

<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3'

export default {
  name: 'HorizontalBarChart',
  props: {
    data: {
      type: Array
    },
    csatView: Boolean
  },
  data() {
    return {
      observer: null
    }
  },
  watch: {
    csatView(newValue, oldValue) {
      this.renderChart()
    },
    data() {
      this.renderChart()
    }
  },
  mounted() {
    this.resizeWindow()
  },
  methods: {
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow() {
      this.observer = new ResizeObserver(() => {
        if (this.data.length) {
          if (d3.select('#tam-barchart').node() != null) {
            this.renderChart()
          }
        }
      })
      this.observer.observe(document.querySelector('#tam-barchart'))
    },
    renderChart() {
      d3.selectAll('#tam-barchart > *').remove()
      // set the dimensions and margins of the graph
      d3.select('#tam-barchart > *').remove()
      const margin = { top: 13, right: 10, bottom: 1, left: 120 }
      const container = document.querySelector('#tam-barchart')
      const width = container.clientWidth - margin.left - margin.right
      const height = container.clientHeight - margin.top - margin.bottom
      const csatView = this.csatView
      // append the svg object to the body of the page
      const svg = d3
        .select('#tam-barchart')
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)

      const data = this.data
      // Add X axis
      const maxDomain = d3.max(data, (d) => d.value)
      var color = [
        '#00297A',
        '#50E6FF',
        '#00A9E0',
        '#0F4AD5',
        '#B3282D',
        '#9063CD',
        '#8F95D3'
      ]
      var csatColor=['#8F95D3','#00A9E0','#CBBF7A','#9063CD','#00297A','#50E6FF','#B3282D']
      const x = d3
        .scaleLinear()
        .domain([0, 1.2 * maxDomain])
        .range([0, width])
      svg
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0, ${height})`)
        // .call(d3.axisBottom(x).ticks(0).tickSize(-height))
        .selectAll('text')
        .attr('transform', 'translate(-10,0)rotate(-45)')
        .style('text-anchor', 'end')

      // const topLine = svg
      //   .append('g')
      //   .attr('transform', 'translate(0, 0)')
      //   .attr('class', 'x-axis top-line')
      //   .call(d3.axisTop(x).ticks(0).tickSize(0))
      //   .selectAll('.domain')
      //   .attr('stroke', 'var(--dot-plot-axis-color)')

      svg
        .selectAll('.x-axis .domain')
        .attr('stroke', 'var(--dot-plot-axis-color)')

      // const rightLine = svg.append('g')
      //   .attr('transform', 'translate(' + width + ', 0)')
      //   .attr('class', 'y-axis right-line')

      // Y axis
      const y = d3
        .scaleBand()
        .range([0, height])
        .domain(data.map((d) => d.vendor))
        .padding(0.5)
      svg
        .append('g')
        .attr('class', 'y-axis')
        .call(d3.axisLeft(y))
        .selectAll('.tick text')
        .call(wrap, 100)
        .attr('fill', 'var(--description)')
        // .attr('fill', '#424242')

      svg.select('.y-axis').selectAll('.tick line').remove()

      svg.select('.y-axis').select('.domain').attr('d', 'M 0,0')

      svg.selectAll('.y-axis text').style('font-size', 12)
      svg.selectAll('.y-axis text').style('font-family', 'Quicksand')

      svg.select('.x-axis').selectAll('.tick').selectAll('text').remove()

      svg
        .select('.x-axis')
        .selectAll('.tick line')
        .attr('stroke', 'var(--dot-plot-axis-color)')

      // Bars
      if (csatView) {
        svg
          .selectAll('myRect')
          .data(data)
          .join('rect')
          .attr('x', x(0))
          .attr('y', (d) => y(d.vendor))
          .attr('width', (d) => x(d.value))
          .attr('height', y.bandwidth())
          .attr('fill', function (d, i) {
            return csatColor[i % 7]
          })
      } else {
        svg
          .selectAll('myRect')
          .data(data)
          .join('rect')
          .attr('x', x(0))
          .attr('y', (d) => y(d.vendor))
          .attr('width', (d) => x(d.value))
          .attr('height', y.bandwidth())
          .attr('fill', function (d, i) {
            return color[i % 7]
          })
      }

      // if (csatView) {
      // svg.selectAll('partnerVolume')
      //   .data(data)
      //   .join('text')
      //   .attr('x', d => x(d.quantity) - 5)
      //   .attr('text-anchor', 'end')
      //   .text(d => d.quantity)
      //   .attr('fill', 'var(--description)')
      //   .style('font-size', function (d, i) {
      //     return window.getComputedStyle(d3.selectAll('.y-axis text')._groups[0][i]).fontSize
      //   })
      //   .attr('y', function (d) {
      //     return y(d.vendor) + (y.bandwidth() + Number(d3.select(this).node().style.fontSize.slice(0, -2))) / 2.35
      //   })

      // svg.selectAll('csatValues')
      //   .data(data)
      //   .join('text')
      //   .attr('x', d => x(d.quantity) + 5)
      //   .text(d => d.csatRating)
      //   .attr('fill', 'var(--description)')
      //   .style('font-size', function (d, i) {
      //     return window.getComputedStyle(d3.selectAll('.y-axis text')._groups[0][i]).fontSize
      //   })
      //   .attr('y', function (d) {
      //     return y(d.vendor) + (y.bandwidth() + Number(d3.select(this).node().style.fontSize.slice(0, -2))) / 2.35
      //   })
      // } else {

      svg
        .selectAll('barValues')
        .data(data)
        .join('text')
        .attr('x', (d) => x(d.value) + 5)
        .text((d) => d.value + (csatView ? '' : '%'))
        // .attr('fill', 'var(--description)') #070E27
        .attr('fill', 'var(--description)')
        .style('font-size', function (d, i) {
          return window.getComputedStyle(
            d3.selectAll('.y-axis text')._groups[0][i]
          ).fontSize
        })
        .attr('y', function (d) {
          return (
            y(d.vendor) +
            (y.bandwidth() +
              Number(d3.select(this).node().style.fontSize.slice(0, -2))) /
              2.35
          )
        })
      // }

      function wrap(text, width) {
        text.each(function () {
          const self = d3.select(this)
          let textLength = self.node().getComputedTextLength()
          let textContent = self.text()
          while (textLength > width - 10 && textContent.length > 0) {
            textContent = textContent.slice(0, -1)
            self.text(textContent + '...')
            textLength = self.node().getComputedTextLength()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#tam-barchart {
  width: 100%;
  height: 100%;
}
</style>
